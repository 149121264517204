export default {
  data: () => ({
    onSave: false,
    isSuccess: false,
    saveResult: null,
  }),
  methods: {
    async save() {
      this.$root.$emit(`form/${this.formKey}/beforeSave`)
      this.$root.$emit(`form/${this.formKey}/submit`)

      if(this.onSave) return
      if(this.validateForm() === false) return
      this.onSave = true
      this.isSuccess = false
      this.saveResult = null
      this.$store.dispatch(`form/${this.formKey}/setError`, {})

      await this.saveRequest()
      this.onSave = false

      // 成功
      if(this.isSuccess) {
        await this.afterSaveSuccessfully(this.saveResult)
        this.redirect()
      }

      if(typeof this.formSaveFinished === 'function') {
        this.formSaveFinished(this.saveResult)
      }
    },
    validateForm() {
      // 自訂前端表單驗證
      if(typeof this.formActions.validateBeforeSave === 'function') {
        const error = this.formActions.validateBeforeSave()
        if(error) {
          this.$store.dispatch(`form/${this.formKey}/setError`, error)
          return false
        }
      }

      // 前端驗證失敗, 資料不完整
      if(!this.finished) {
        this.$snotify.error(
          this.$t('save.failure.unfinished'),
          this.$t('save.failure'),
        )
        return false
      }

      return true
    },
    async saveRequest() {
      this.$store.dispatch('loading/active')
      this.$store.dispatch('loading/progress')

      try {
        this.saveResult = await this.getSaveApi()
        this.isSuccess = true

        this.$snotify.success(
          null,
          this.$t('save.successfully')
        )
      } catch (error) {
        console.error(error)
        this.handleError(error)
      } finally {
        this.$store.dispatch('loading/close')
        this.$store.dispatch('loading/closeProgress')
      }
    },
    handleError(error) {
      const status = error.status
      const errorJson = error.data
      this.$store.dispatch(`form/${this.formKey}/setError`, errorJson)

      let message = null
      if(status == 400) {
        message = this.$t('save.failure.data_incorrect')
      }

      else {
        message = this.$t('error.unexpect')
      }

      if(typeof this.afterSaveFailCallback === 'function') {
        let customErrorNotifyMessage = this.afterSaveFailCallback(status, errorJson)
        if(customErrorNotifyMessage) message = customErrorNotifyMessage
      }

      this.$snotify.error(
        message,
        this.$t('save.failure')
      )
    },
    async afterSaveSuccessfully() {
      if(typeof this.afterSaveSuccessCallback === 'function') {
        await this.afterSaveSuccessCallback(this.$eagleLodash.cloneDeep(this.saveResult))
      }
      if(this.isSuccess && this.saveResult) {
        this.formActions.resetFormData(this.saveResult)
      }
    },
    redirect() {
      if(this.formMode == 'create') {
        if(typeof this.formConfig.afterCreateRoute === 'function') {
          const route = this.formConfig.afterCreateRoute(this.saveResult)
          this.$router.push(route)
        }
        return
      }
    },
    async getSaveApi() {
      if(this.formMode == 'create') return await this.formApi.createApi(this.params)
      return await this.formApi.updateApi(this.formTarget, this.params)
    },
  },
  computed: {
    formTarget() {
      return this.$store.getters[`form/${this.formKey}/target`];
    },
    params() {
      let formData = window.eagleLodash.cloneDeep(this.formData)
      if(typeof this.setParams != 'function') return formData
      return this.setParams(formData)
    },
  },
}
