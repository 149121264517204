import eagleForm from '@/components/form/form.vue'
import { mapGetters } from 'vuex'
export default {
  methods: {
    _setupBreadcrumb() {
      if (!this.bindRoute) return
      let breadcrumb = []
      if (typeof this.formConfig.from === 'function') {
        const from = this.formConfig.from().map(item => {
          item.label = this.$t(item.label)
          return item
        })
        breadcrumb = breadcrumb.concat(from)
      }
      breadcrumb.push({
        label: this.pageTitle,
      })
      this.$store.dispatch('base/setBreadcrumb', breadcrumb)
    },
    _hasModule() {
      return this.$store.getters[`form/${this.formKey}/config`] != undefined
    },
    _destroyVuexModule() {
      if (this._hasModule() === false) return
      this.$store.unregisterModule(['form', this.formKey])
    },
    checkHasWritePermission(formConfig) {
      if (!formConfig) return true
      if (typeof formConfig.hasWritePermission != 'function') return true
      return formConfig.hasWritePermission((...args) => window.tokenStore.hasRole(...args))
    },
  },
  computed: {
    // read的key, 如果不是bind route請由外部vue設定computed target覆蓋
    target() {
      return this.$route.params.target
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    formMeta() {
      return this.$store.getters[`form/${this.formKey}/meta`]
    },
    formMode() {
      return this.$store.getters[`form/${this.formKey}/mode`]
    },
    formApi() {
      return this.$store.getters[`form/${this.formKey}/api`]
    },
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    formDefaultData() {
      return this.$store.getters[`form/${this.formKey}/defaultData`]
    },
    finished() {
      return this.$store.getters[`form/${this.formKey}/finished`]
    },
    hasWritePermission() {
      return this.$store.getters[`form/${this.formKey}/hasWritePermission`]
    },
  },
  components: {
    eagleForm,
  },
}